import { Container, Typography } from "@mui/material";

export const About = () => {
  return (
    <Container maxWidth="lg">
      <br />
      <br />
      <Typography variant="h3" textAlign="center">
        <b>About Us</b>
      </Typography>
      <br />
      <br />
      <Typography>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quaerat, iste
        quia voluptas animi at voluptatem maiores aspernatur aliquam similique
        ut nesciunt quis. Laborum fuga numquam hic rem facilis recusandae
        delectus, deleniti error nobis voluptatibus sed, commodi eos labore
        consequuntur nisi ducimus iusto harum pariatur exercitationem saepe
        laudantium. Magnam ipsam, velit eaque rerum, nihil unde fuga debitis
        itaque iste perspiciatis voluptatibus ab quam aut nesciunt, facilis vero
        esse totam quidem numquam nisi! Dignissimos, perferendis! Distinctio
        aliquid mollitia ipsam deserunt quos! Explicabo in ad ratione incidunt
        amet, necessitatibus quia culpa consequuntur dolore dolor ea maxime,
        blanditiis adipisci itaque dicta aliquam consectetur distinctio.
      </Typography>
      <br />
      <Typography>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quaerat, iste
        quia voluptas animi at voluptatem maiores aspernatur aliquam similique
        ut nesciunt quis. Laborum fuga numquam hic rem facilis recusandae
        delectus, deleniti error nobis voluptatibus sed, commodi eos labore
        consequuntur nisi ducimus iusto harum pariatur exercitationem saepe
        laudantium. Magnam ipsam, velit eaque rerum, nihil unde fuga debitis
        itaque iste perspiciatis voluptatibus ab quam aut nesciunt, facilis vero
        esse totam quidem numquam nisi! Dignissimos, perferendis! Distinctio
        aliquid mollitia ipsam deserunt quos! Explicabo in ad ratione incidunt
        amet, necessitatibus quia culpa consequuntur dolore dolor ea maxime,
        blanditiis adipisci itaque dicta aliquam consectetur distinctio.
      </Typography>
      <br />
      <Typography>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quaerat, iste
        quia voluptas animi at voluptatem maiores aspernatur aliquam similique
        ut nesciunt quis. Laborum fuga numquam hic rem facilis recusandae
        delectus, deleniti error nobis voluptatibus sed, commodi eos labore
        consequuntur nisi ducimus iusto harum pariatur exercitationem saepe
        laudantium. Magnam ipsam, velit eaque rerum, nihil unde fuga debitis
        itaque iste perspiciatis voluptatibus ab quam aut nesciunt, facilis vero
        esse totam quidem numquam nisi! Dignissimos, perferendis! Distinctio
        aliquid mollitia ipsam deserunt quos! Explicabo in ad ratione incidunt
        amet, necessitatibus quia culpa consequuntur dolore dolor ea maxime,
        blanditiis adipisci itaque dicta aliquam consectetur distinctio.
      </Typography>
    </Container>
  );
};
