import style from "./style.module.css";
import { Typography } from "@mui/material";

export const SignUp = () => {
  return (
    <section className={style.main_sec}>
      <Typography textAlign="center">
        <b>Sign Up</b>
      </Typography>
    </section>
  );
};
